@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');
@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

.font-pixelated {
  font-family: 'Press Start 2P', 'system-ui';
}

.font-cmd {
  font-family: 'JetBrains Mono', 'monospace';
}

body {
  cursor: url('../public/cursor.cur'), auto;
}

*:hover {
  cursor: url('../public/cursor.cur'), auto;
}


/*! COORDS: x25 y10 */

.titlescreen-background {
  background-image: url('./assets/titlescreen.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
  overflow: hidden;
  transition: transform 0.4s ease-in-out;
}

.titlescreen-background.zoomed {
  transform: scale(3);
}

.button-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.button-container button {
  /* Add your button styles here */
}

@keyframes blink {

  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }
}

.animate-blink {
  animation: blink 2s infinite;
}